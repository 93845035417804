<template>
<div class="box-container">
  <div class="box-slider">
    <MyTree class="box-tree" ref="tree" :data="treeData" />
    <List v-if="filter.length > 0" border size="small" class="tree-search-result">
      <template v-if="filterList.length == 0">{{showLang('com.data.no')}}</template>
      <template v-else v-for="(item, idx) in filterList">
        <ListItem v-if="item.type == 'group'" class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.group')}}：{{item.name}}</ListItem>
        <ListItem v-else class="node-selected" @click.native="selectIt(item)" :key="idx">{{showLang('com.site')}}：[{{item.code}}] {{item.name}}</ListItem>
      </template>
    </List>
  </div>
  <div class="tree-search">
    <img :src="img.treeSearchImg"/>
    <input type="text" v-model="filter" :placeholder="showLang('save.code.name.dev')" />
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
import MyTree from './MyTree'
export default {
  name: 'CommonTreeIndex',
  components: {
    MyTree,
  },
  props: {
    groups: {
      type: Array,
      default(){return [];},
    },
  },
  data(){
    return {
      showEditGroup: false,
      showChangeGroup: false,
      editItem: {},
      filter:'',
      result: [],
    }
  },
  watch: {
    filter(newVal){
      this.$store.commit('group/filterTree', newVal);
    },
  },
  computed: {
    ...mapState('group', ['treeData', 'devices', 'selectedNode', 'filterList']),
    ...mapGetters('auth', ['showLang']),
  },
  mounted: function(){
  },
  destroyed: function(){
  },
  methods: {
    selectIt: function(nodeData){
      this.$store.commit('group/selectNodeById', nodeData.id);
    },
  }
}
</script>
<style scoped>
.box-container{
  background: #E3E9EF;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
  position: relative;
}
.box-slider{
  flex: none;
  width: 100%;
  background-color: #041B32;
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
}
.box-tree{
  flex: auto;
}
.tree-search{
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 34px;
  background-color: #132D48;
  /* border-radius: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: solid 1px red; */
}
.tree-search img{
  /* outline-style: none; */
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.tree-search input{
  outline-style: none;
  width: 210px;
  height: 15px;
  font-size: 14px;
  background-color: #132D48;
  border: 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4D6781;
}
.tree-search-result{
  position: absolute;
  bottom: 50px;
  left: 10px;
  right: 0;
  width: 310px;
  background: white;
  max-height: 300px;
  overflow-y: auto;
}
.node-selected{
  cursor: pointer;
}
</style>
